import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Faq from 'react-faq-component'
import SEO from '../components_v2/seo'
import { colors } from '../utils'
import LayoutV2 from '../components_v2/Layout'
import { Container, Wrapper } from '../styles/Faq'

const styles = {
  bgColor: 'white',
  titleTextColor: colors.primary,
  rowTitleColor: colors.gray,
  rowContentColor: colors.gray2,
  arrowColor: colors.black,
}

const config = {
  // animate: true,
  // arrowIcon: "V",
}
const FaqPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allSiteContentsJson {
        nodes {
          faq {
            rows {
              content
              title
            }
          }
        }
      }
    }
  `)
  const { faq } = data.allSiteContentsJson.nodes[0]
  return (
    <LayoutV2>
      <SEO title="Frequently Asked Questions" />
      <Wrapper>
        <Container>
          <Faq data={faq} styles={styles} config={config} />
        </Container>
      </Wrapper>
    </LayoutV2>
  )
}

export default FaqPage
