import styled from 'styled-components'

export const Wrapper = styled.div`
  min-height: 600px;
`
export const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 100ch;
  margin: auto;
  background: white;
  padding: 2rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 0.7rem;
  margin-top: 7rem;
  margin-bottom: 1rem;
`
